import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import useThemeContext from "../../../hooks/useThemeSwitch";
import { setRootContainerWidth } from "../../../reducers/layout/layoutReducer";
import CredentialInventory from "../../../components/Credentials/CredentialInventory";
import ValetInventory from "../../../components/ValetInventory/ValetInventory";

import { useFlags } from "launchdarkly-react-client-sdk";
import { Typography } from "@material-ui/core";
import { useStyles } from "./style";
import { ENTITY_TYPE } from "../../../constants";

const CredentialInventoryPage = (props) => {
  const { layoutReducer } = useThemeContext();
  const [, layoutDispatch] = layoutReducer;
  const { parkingInventory } = useFlags();

  const entityType = useSelector((state) => state.entities?.Context?.typeid);
  const isValetAreaEntity = entityType === ENTITY_TYPE.ValetArea;

  useEffect(() => {
    layoutDispatch({ type: setRootContainerWidth, payload: false });
  }, [layoutDispatch]);

  return (
    <Switch>
      {parkingInventory ? (
        isValetAreaEntity ? (
          <Route exact path="/inventory" component={ValetInventory} />
        ) : (
          <Route exact path="/inventory" component={CredentialInventory} />
        )
      ) : (
        <Route exact path="/inventory" component={CredentialInventoryMaintenancePage} />
      )}
    </Switch>
  );
};

const CredentialInventoryMaintenancePage = () => {
  const classes = useStyles();
  return (
    <Typography
      className={classes.inventoryMaintence}
      data-testid="inventory-maintenance"
    >
      This feature is undergoing a few improvements and will be available soon.
    </Typography>
  );
};

export default CredentialInventoryPage;
